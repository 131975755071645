import React from 'react'
import Meta from 'src/components/Meta'
import './cli.css'

const CLIPage = () => {
  return (
    <main className="Main">
      <Meta
        title="cointop - command-line interface (CLI)"
        description={
          'A fast and lightweight interactive terminal based UI application for tracking cryptocurrencies. Features include: vim-inspired key bindings, fast pagination, save and view favorite coins, chart views, and more.'
        }
      />
      <header className="Header">
        <h1>
          <a href="/">
            <img
              src="https://user-images.githubusercontent.com/168240/39561871-51cda852-4e5d-11e8-926b-7692d43143e8.png"
              alt="cointop"
            />
          </a>
        </h1>
      </header>
      <section className="Tagline">
        <p>
          A fast and lightweight interactive terminal based UI application for
          tracking cryptocurrencies
        </p>
      </section>
      <section className="Body">
        <div className="Display">
          <a href="https://github.com/cointop-sh/cointop">
            <img
              src="https://user-images.githubusercontent.com/168240/39569578-7ce9f3b6-4e7a-11e8-82a9-8a18b91b1bd5.png"
              alt="cointop screenshot"
            />
          </a>
        </div>
        <div className="Actions">
          <a href="https://github.com/cointop-sh/cointop" className="Button">
            View on Github
          </a>
        </div>
        <div className="Demo">
          <label>Try demo without installing anything</label>
          <code>ssh cointop.sh</code>
        </div>
        <div className="Install">
          <label>Installation instructions</label>
          <details>
            <summary>Using Go (always latest)</summary>
            <div>
              <code>go get github.com/cointop-sh/cointop</code>
            </div>
          </details>
          <details>
            <summary>Homebrew (macOS)</summary>
            <div>
              <code>brew install cointop</code>
            </div>
          </details>
          <details>
            <summary>Snap (Ubuntu)</summary>
            <div>
              <code>sudo snap install cointop</code>
            </div>
          </details>
          <details>
            <summary>Copr (Fedora)</summary>
            <div>
              <code>
                sudo dnf copr enable miguelmota/cointop -y sudo dnf install
                cointop
              </code>
            </div>
          </details>
          <details>
            <summary>AUR (Arch)</summary>
            <div>
              <code>yay -S cointop</code>
            </div>
          </details>
          <details>
            <summary>Flatpak (Linux)</summary>
            <div>
              <code>
                sudo flatpak remote-add --if-not-exists flathub
                https://flathub.org/repo/flathub.flatpakrepo sudo flatpak
                install flathub com.github.miguelmota.Cointop flatpak run
                com.github.miguelmota.Cointop
              </code>
            </div>
          </details>
          <details>
            <summary>FreshPorts (*BSD)</summary>
            <div>
              <code>sudo snap install cointop</code>
            </div>
          </details>
          <details>
            <summary>Windows</summary>
            <div>
              <code>go get github.com/cointop-sh/cointop</code>
            </div>
          </details>
          <details>
            <summary>Docker</summary>
            <div>
              <code>docker run -it cointop/cointop</code>
            </div>
          </details>
        </div>
        <div className="Subscribe">
          <div id="mc_embed_signup">
            <form
              action="https://cointop.us17.list-manage.com/subscribe/post?u=de587fb0e5f16636e2fe9c789&amp;id=7edb036ff0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
            >
              <div className="Join">Join the mailing list</div>
              <div id="mc_embed_signup_scroll">
                <div className="mc-field-group">
                  <label htmlFor="mce-EMAIL">Email Address </label>
                  <input
                    type="email"
                    defaultValue=""
                    name="EMAIL"
                    className="required email"
                    id="mce-EMAIL"
                    placeholder="alice@example.com"
                  />
                </div>
                <div id="mce-responses" className="clear">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: 'none' }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: 'none' }}
                  ></div>
                </div>
                <div
                  style={{ position: 'absolute', left: '-5000px' }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_de587fb0e5f16636e2fe9c789_7edb036ff0"
                    tabIndex={-1}
                    defaultValue=""
                  />
                </div>
                <div className="clear">
                  <input
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="Button"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <footer className="Footer">
        © 2020 <a href="https://miguelmota.com/">Miguel Mota</a>
      </footer>
    </main>
  )
}

export default CLIPage
